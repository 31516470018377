import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Img from "gatsby-image"
import { graphql, StaticQuery, navigate } from "gatsby"
import { SharedDivider } from "../shared"

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 8px;
  margin: 0 0.8em 0.8em 0;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: #111;
  }
`

const Component = ({
  img,
  name,
  imgs: {
    allFile: { edges },
  },
}) => {
  const imgSharp = edges.find((i) => i.node.name === img).node.childImageSharp
  return (
    <Wrapper>
      <Img
        fixed={imgSharp.fixed}
        aspectRatio={1}
        durationFadeIn={100}
        css={css`
          border-radius: 100%;
          width: 24px !important;
          height: 24px !important;

          img {
            object-fit: cover !important;
          }
        `}
      />

      <p>{name}</p>
    </Wrapper>
  )
}

export const PreparedBy = (props) => (
  <StaticQuery
    query={graphql`
      query kuk {
        allFile(filter: { relativeDirectory: { eq: "mentor" } }) {
          edges {
            node {
              id
              name
              absolutePath
              relativePath
              publicURL
              childImageSharp {
                fixed(width: 40, pngCompressionSpeed: 1, quality: 80) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={(imgs) => <Component {...props} imgs={imgs} />}
  />
)
