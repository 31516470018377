import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import MinusSVG from "../../images/icons/minus.svg"
import PlusSVG from "../../images/icons/plus.svg"
import EyeSVG from "../../images/icons/eye.svg"
import BookSVG from "../../images/icons/bxs-book.svg"
import xSVG from "../../images/icons/x3.svg"
import SanitizedHTML from "react-sanitized-html"
import { SharedModalBackground } from "../shared"
import ScrollLock from "react-scrolllock"
import OutsideClickHandler from "react-outside-click-handler"
import { PreparedBy } from "./PreparedBy"
import { isMobile } from "react-device-detect"

const QuestionBlock = styled.div`
  background: #fff;
  border: 1px solid #e1e2e3;
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-column-gap: 10px;
  align-items: center;
  padding: 0 10px;
  min-height: 50px;
  padding: 10px;
  width: 100%;
`

const AnswerBlock = styled.div`
  box-sizing: border-box;
  color: #525151;
  height: ${(props) => (props.isOpen ? "auto" : "0px")};
  overflow: hidden;
  padding: ${(props) => (props.isOpen ? `12px 0px` : `0px 0px`)};
  font-size: 16px;
  line-height: 1.7;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  margin-top: ${(props) => (props.isOpen ? `0px` : `-5px`)};
  transition: all 0.2s;

  p {
    margin: 0 0 0.3em 0;
  }
`

const HeaderText = styled.div`
  display: flex;

  h3 {
    font-weight: 600;
    color: #111;
    margin: 0;
    font-size: 1.429em;
    @media (max-width: 640px) {
      font-size: 1.3em;
    }
  }

  p {
    color: #8f96a2;
    margin: 0;
    font-size: 1.429em;
    @media (max-width: 640px) {
      font-size: 1.3em;
    }
  }
`

const PlusMinus = styled.img`
  transition: all 0.2s;
  height: 20px;
  width: 20px;

  display: ${(props) => (props.display ? "block" : "none")};
`

const EButton = styled.button`
  cursor: pointer;
  background: #fafafa;
  border-radius: 6px;
  display: flex;
  min-height: 50px;
  padding: 10px;
  border: 0;
  color: #525151;
  align-items: center;
  font-weight: 600;

  img {
    width: 20px;
    margin-right: 17px;
  }

  :hover {
    background: #f5f5f5;
  }

  :active {
    background: #eeeeee;
  }
`

export const EModal = styled.div`
  min-width: 15em;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "1px 7px 20px 2px rgba(0, 0, 0, 0.4)"};
  background-color: #fff;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "5px"};
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 82vh;
  width: 600px;
  position: relative;

  @media (max-width: 640px) {
    width: 90vw;
  }
`

export const EModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #111;
  }
  p {
    font-size: 14px;
    color: #525151;
  }
`

export const ScheduleList = ({ schedule }) => {
  if (!schedule) {
    return <h3>Розклад занять оголосимо із початком набору на курс</h3>
  }

  return schedule.map((i, index) => <Item key={`${index}${i.header}`} {...i} />)
}

const Item = (props) => {
  const { header, weeksText, lessons, weeks, preparedBy, date1, date2 } = props
  const [isOpen, setOpen] = useState(false)

  return (
    <div
      css={css`
        margin-bottom: 2em;
        font-family: "Rubik", Arial, Helvetica, sans-serif;
      `}
    >
      <QuestionBlock
        tabindex={0}
        isOpen={isOpen}
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <HeaderText>
          <h3>{header}</h3>
          {`\u00A0\u00A0\u00A0\u00A0`}

          <p>
            {weeksText}
            {`\u00A0\u00A0`}
          </p>
          {lessons && (
            <>
              <p>
                •{`\u00A0\u00A0`}
                {lessons}
              </p>
            </>
          )}

          {lessons && date1 &&  date2 && (
            <>
              <p>
                •{`\u00A0\u00A0`}
                {date1} - {date2}
              </p>
            </>
          )}
        </HeaderText>

        <div>
          <PlusMinus display={isOpen} src={MinusSVG} />
          <PlusMinus display={!isOpen} src={PlusSVG} />
        </div>
      </QuestionBlock>

      <AnswerBlock isOpen={isOpen}>
        {weeks.map((i, index) => (
          <ItemButton
            key={`ibtn${index}${header}`}
            {...i}
            moduleHeader={header}
            preparedBy={preparedBy}
          />
        ))}
      </AnswerBlock>
    </div>
  )
}

const ItemButton = (props) => {
  const { moduleHeader, header, lessons, preparedBy } = props
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      {isOpen && (
        <>
          <SharedModalBackground backgroundColor={"#00000054"} />
          {!isMobile && <ScrollLock />}
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              width: 100vw;
              height: 100vh;
              top: 0;
              left: 0;
              z-index: 1100;
            `}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpen(false)
              }}
            >
              <EModal>
                <>
                  <EModalContent>
                    <div
                      css={css`
                        padding: 20px 20px 0 20px;
                      `}
                    >
                      <h4
                        css={css`
                          padding: 20px 0;
                          margin: 0 0 10px 0;
                          display: flex;
                          border-bottom: 1px solid #e1e2e3;
                          align-items: center;
                          justify-content: space-between;
                        `}
                      >
                        {moduleHeader}
                        <img
                          src={xSVG}
                          onClick={() => {
                            setOpen(false)
                          }}
                          css={css`
                            height: 12px;
                            cursor: pointer;
                          `}
                        />
                      </h4>
                      {lessons && (
                        <>
                          {lessons.map((i, index) => (
                            <div
                              key={`${index}${i.header.substring(0, 7)}`}
                              css={css`
                                display: flex;
                                flex-direction: column;
                              `}
                            >
                              <h4
                                css={css`
                                  margin: 10px 0 15px 5px;
                                `}
                              >
                                <img
                                  src={BookSVG}
                                  css={css`
                                    height: 14px;
                                    margin-right: 10px;
                                  `}
                                />
                                {i.header}
                              </h4>
                              {i.list && (
                                <>
                                  {i.list.map((j, index) => (
                                    <div
                                      css={css`
                                        display: flex;
                                      `}
                                      key={`lessonslist${index}${j.substring(
                                        0,
                                        6
                                      )}`}
                                    >
                                      <div
                                        css={css`
                                          display: inline;
                                          margin: 0 10px;
                                        `}
                                      >
                                        •
                                      </div>

                                      <SanitizedHTML html={j} />
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    {!!preparedBy ? (
                      <>
                        <div
                          css={css`
                            background-color: #fafafa;
                            margin-top: 1.2em;
                            padding: 8px 20px 0 20px;
                            display: flex;
                            margin-top: 1.2em;

                            p {
                              margin: 0;
                              font-weight: 600;
                              font-size: 14px;
                              color: #111;
                              margin-right: ;
                            }
                          `}
                        >
                          <p>Підготували:</p>
                        </div>
                        <div
                          css={css`
                            background-color: #fafafa;
                            padding: 10px 20px;
                            display: flex;
                            flex-direction: column;
                          `}
                        >
                          {preparedBy.map((i, index) => (
                            <PreparedBy
                              name={i.name}
                              img={i.img}
                              key={`preparedby2${i.name}${index}`}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <div
                        css={css`
                          height: 10px;
                        `}
                      />
                    )}
                  </EModalContent>
                </>
              </EModal>
            </OutsideClickHandler>
          </div>
        </>
      )}

      <EButton
        onClick={() => {
          setOpen(true)
        }}
      >
        <img src={EyeSVG} /> {header}
      </EButton>
    </>
  )
}
