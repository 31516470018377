import React, { useState } from "react"
import { css } from "@emotion/react"
import { CarouselElement } from "./CarouselElement"
import Slider from "react-slick"
import styled from "@emotion/styled"
import LinkedinSVG from "../../images/icons/linkedin.svg"
import a1 from "../../images/studentFeedbacks/ivan-kyvatskyi.jpeg"
import a11 from "../../images/studentFeedbacks/roman-kushytskyy.png"
import a2 from "../../images/studentFeedbacks/yulia-bobko.png"
import a3 from "../../images/studentFeedbacks/Andrii-Petryk.png"
import a4 from "../../images/studentFeedbacks/rostyslav-pidburachynskyi.png"
import a5 from "../../images/studentFeedbacks/Petro-Liashchynskyi.png"
import a6 from "../../images/studentFeedbacks/vasyl-kostin.jpeg"
import a7 from "../../images/studentFeedbacks/q.jpg"
import a9 from "../../images/studentFeedbacks/Serhii-Trachuk.png"
import a10 from "../../images/studentFeedbacks/vadym-myronov.png"
import a12 from "../../images/studentFeedbacks/mykola-yanush.jpg"
import a13 from "../../images/studentFeedbacks/dmytro-hrab.png"
import a14 from "../../images/studentFeedbacks/roman-tymets.png"
import a15 from "../../images/studentFeedbacks/mykhailo-pasternak.png"
import a16 from "../../images/studentFeedbacks/serhii-saviuk.jpg"
import a17 from "../../images/studentFeedbacks/oleh-ravlyk.jpeg"
import a18 from "../../images/studentFeedbacks/andrii-kukliak.png"
import a19 from "../../images/studentFeedbacks/vadym-boyarchuk.png"
import a20 from "../../images/studentFeedbacks/vitalii-kulchytskii.png"

const arrowStyle = css`
  outline: 0;
  z-index: 10;
  cursor: pointer;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: fit-content;
  margin: 10px;
  overflow: visible;

  width: 11px !important;
  height: 18px !important;

  @media (max-width: 900px) {
    width: 10px !important;
    width: 15px !important;
  }
`

const PrevArrow = styled.button`
  left: 0;
  background-color: transparent !important;
  ${arrowStyle}
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.12 11.41'%3E%3Ctitle%3EVector%3C/title%3E%3Cpath d='M6.29.29.59,6l5.7,5.71,1.42-1.42L3.41,6l4.3-4.29Z' transform='translate(-0.59 -0.29)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto auto auto -2em;
  z-index: 23;
`

const NextArrow = styled.button`
  right: 0;
  margin-left: -2.25em;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent !important;
  ${arrowStyle}
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.19 11.42'%3E%3Ctitle%3E43141%3C/title%3E%3Cpath d='M1.33,11.39,7.12,5.77,1.51,0,.07,1.36,4.3,5.72-.07,9.94Z' transform='translate(0.07 0.03)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 23;
  margin: auto -2em auto auto;
`

const Component = () => {
  const [feedbacks, setFeedbacks] = useState(fList2)

  const toggleExpandedState = (id) => {
    setFeedbacks(
      feedbacks.map((i) => ({
        ...i,
        expanded: i.id === id ? !i.expanded : i.expanded,
      }))
    )
  }

  return (
    <section
      id="feedbackCarousel"
      css={css`
        .slick-list {
          transition: height 0.2s;
        }

        position: relative;
        max-width: 96.25em;
        margin: 0 auto;
        padding: 0 2.5em;

        .slick-dots {
          position: absolute;
          width: 100%;
          bottom: -36px;
          padding: 0;
          margin: 2.5em 0 0 0;
          display: flex !important;
          justify-content: center;
          align-items: center;

          button {
            background-color: transparent;
            border: 1px solid #fd7114;
            cursor: pointer;
            content: "";
            width: 7px;
            height: 7px;
            padding: 0;
            border-radius: 100%;
            font-size: 0.001px;
            background-color: transparent;
            transition: all 0.3s;
            :hover {
              background-color: #fff7ee;
              transform: scale(1.4);
            }
          }

          .slick-arrow {
            height: fit-content;
            width: fit-content;
          }

          li {
            transition: all 0.3s cubic-bezier(0, 1, 1, 1);
            list-style-type: none;
            border-radius: 100%;

            margin: 7px;
            display: block;

            button {
              display: block;
              width: 8x;
              height: 8x;
            }

            @media (max-width: 900px) {
              margin: 4px;
              width: 5px;
              height: 5px;
            }
          }

          .slick-active::marker {
            button {
              background-color: #fd7114 !important;
            }
          }

          .slick-active {
            visibility: visible !important;

            button {
              background-color: #fd7114 !important;
            }
          }
        }

        @media (max-width: 93em) {
          max-width: calc(100vw - 80px);
        }

        @media (max-width: 640px) {
          max-width: calc(100vw - 40px);
        }
      `}
    >
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        adaptiveHeight={true}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        autoplaySpeed={10000}
        dots={true}
      >
        {feedbacks.map((i, index) => {
          return (
            <CarouselElement
              key={i.id}
              id={i.id}
              expanded={i.expanded}
              name={i.name}
              purpose={i.purpose}
              msg={i.msg}
              photo={i.photo}
              companyLogo={null}
              componentNextToName={i.componentNextToName}
              noExtend={i.noExtend}
              toggleExpandedState={toggleExpandedState}
            />
          )
        })}
      </Slider>
    </section>
  )
}

export const FeedbackCarousel = () => {
  return <Component />
}

const f1 = () => (
  <>
    <p>
      Про курси я дізнався із facebook. При проходженні курсу найбільше
      сподобалось те, що курси охоплювали все від базового js до монго і
      метеора.
    </p>

    <p>
      Під час навчання було досить багато незнайомих речей, доводилось багато
      вчити нового, але в цьому був кайф, до менторів не звертався.
    </p>

    <p>
      Курси дали мені найголовніше поняття, що веб це не тільки верстка і
      анімації, а набагато ширший спектр знань.
    </p>

    <p>Якщо хтось питає які курси по скриптах то тільки apiko</p>
  </>
)

const lkdn = (str) => (
  <a href={str} target="_blank" rel="noreferrer">
    <img
      src={LinkedinSVG}
      css={css`
        width: 1.2em;
        height: 1.2em;
        margin: auto auto auto 20px;
        display: inline !important;
        transform: translatey(0.12em);
      `}
    />
  </a>
)

const f2 = () => (
  <>
    <p>
      Курси від Апіко мені радили багато друзів та знайомих, згодом я знайшла їх
      у соцмережах і не вагаючись вирішила зареєструватись.
      <br />
      Формат самих курсів дуже сподобався, оскільки викладачі подавали матеріал
      дуже чітко і зрозуміло. Після кожної лекції нам потрібно було виконати
      практичне завдання для того, щоб закріпити почутий матеріал. В разі, коли
      щось не вдавалось чи було незрозуміло, чи виникали проблеми із практичним
      завданням – викладачі постійно були на зв’язку.
      <br />В кінці курсів нам потрібно було виконати фінальне завдання -
      розробити власний веб-додаток і вже за результатами цього завдання можна
      було працевлаштуватись у компанії
    </p>
  </>
)

const f3 = () => (
  <>
    <p>
      Про курси дізнався, знайшовши в Youtube відео із попередніх Apiko
      Intensive. Відео були цікаві і доступні, тому у серпні 2019 року я вирішив
      записатись на наступні курси, що якраз починались наприкінці вересня чи
      початку жовтня того ж року. На пошту вислали тестове завдання по HTML/CSS
      i JS, виконавши його я отримав через деякий час фідбек по ньому і
      запрошення стати учасником курсів
      <br />
      Перевагою були цікаві завдання, зручний час занять і великим плюсом для
      мене було те, що всі матеріали занять можна було переглянути на ютубі, бо
      в перший місяць курсів я знаходився за кордоном.
      <br />
      Найбільше сподобалось доступність подачу матеріалу, ментори давали
      відповіді на всі запитання, перевіряли кожне завдання і давали фідбек.
      Мінусів не можу назвати. Усі питання, що виникали під час навчання можна
      було запитувати у телеграм-чаті або ж писати напряму до ментора і
      отримувати допомогу.”
    </p>
  </>
)

const f4 = () => (
  <>
    <p>
      Викладач порадив курси від Apiko. Мені сподобався результат після навчання
      та півтора місяці наполегливої роботи, адже сам би навряд чи так працював.
      <br />
      Досвід, отриманий на курсах, для мене цінний не тільки знаннями, а ще
      знайомством із колегами, я побачив які можуть бути напарники і що можна
      очікувати в майбутньому.
    </p>
  </>
)

const f5 = () => (
  <>
    <p>
      З компанією знайомий ше з часів JSSolutions. Я тоді вчився на 3 курсі,
      шукав базу для практики. Списався із Юрою, і він запропонував пройти
      співбесіду. Співбесіду я пройшов успішно, проте, на той час мої плани
      склались трохи по-іншому і я не пішов працювати у Apiko.
      <br />
      Потім вступив на магістратуру, закінчив її і подумував вже про пошук
      роботи. Шукав фірми в Тернополі з тим стеком технологій, який мені
      подобається. Тому знову написав до Юри, він запропонував пройти курси, бо
      на той момент не було відкритих вакансій.
      <br />
      На курсах все було круто, багато практичних завдань, реально багато. З
      теорією ми ознайомлювалися через відео з минулих курсів. Якщо навіть
      виникали якісь труднощі, то ментори охоче допомагали.
      <br />
      Теорія була актуальна, але потрібно було гуглити багато інфи при виконанні
      специфічних тасок.
    </p>
    <p>Після курсів я пройшов співбесіду, і от я тут :)</p>
  </>
)

const f6 = () => (
  <>
    <p>
      Дізнався від брата та його друзів про те, що Apiko проводить навчальні
      курси. При проходженні навчання цікаво було мати справу з додатком та
      можливістю його зміни в реальному часі.
      <br />
      Реакт супер, та мобільна частина теж супер, зараз би сказав, що з мобіксом
      було важко розібратися, так як на початку взагалі не знав про стейт
      менеджери :)
      <br />
      Із менторами ми були постійно на зв’язку, найважче було вникнути, як все
      будувати, як дебажити, та виправляти ерори.
      <br />
      Якщо чесно, завжди кажу тим друзям, які хочуть почати в айті, що апіко
      проводить курси, та на них варто йти, кращих курсів ще не бачив.
    </p>
  </>
)

const f7 = () => (
  <>
    <p>Дізнався про Apiko Intensive із соцмереж.</p>
    <p>
      Що дали мені ці курси? Інтерв'ю і роботу в апіко, якби я зовсім не ходив
      на ці курси я б мабуть не наважився б на інтерв'ю по технології з якою я в
      той час не працював регулярно.
      <br />
      Із переваг можу виділити актуальні теми та круті фінальні проекти. А
      також, можливість проходити їх віддалено із іншого міста.
      <br />
      Із того, що хотілось би покращити - це довгі ролики з завданнями тільки
      після всього відео-уроку, якщо б можна було придумати як їх розділити на
      менші, менше розказувати в відео і більше залишати посилань на статті які
      варто прочитати, а в відосах тільки якісь особливості застосування на
      практиці пояснювались, було б круто.
      <br />
      Загалом, я задоволений проходженням курсів і рекомендую їх своїм друзям та
      знайомим!
    </p>
  </>
)

const f8 = () => (
  <>
    <p>
      Мені завжди подобалось програмування, тому не дивно, що я цікавився цією
      темою. На попередній роботі, не пов’язаній з програмуванням, доводилось
      стикатись з написанням невеликих програмок та все ж часто вони впирались в
      необхідність структурних знань в тій чи іншій мові.
      <br />
      Для себе я обрав JavaScript тож багато часу проводив в інтернеті вивчаючи
      щось нове, саме так мені в фейсбуці попались курси від Apiko, і це була
      знахідка, яка в підсумку змінила моє життя. Це вже були восьмі їхні курси,
      проходили вони того разу онлайн через епідемію.
      <br />
      На курсах дуже сподобалась подача матеріалу, коротко, стисло і без води.
      Найкраще знання закріплюються на практиці і круто, що на курсах ми
      розробляли реально класний маркетплейс, який точно не соромно додати в
      портфоліо. Проект був розбитий на декілька етапів, впродовж яких нас
      супроводжував досвідчений розробник компанії Apiko. Він допомагав в
      складних моментах, пояснював багато тонкощів, з якими стикаєшся, коли сам
      пишеш код, а основне – рев’ювив код і давав неоціненний фідбек, який і
      мотивував мене розвиватись, і програмувати краще. А ще дуже сподобався
      фінальний етап, коли кожен з учасників представляв свій проект й ділився
      своєю реалізацією тих чи інших фіч в його проекті.
      <br />У загальному курси це не срібна пуля – в навчанні потрібна належна
      наполегливість та старання й бажання саморозвитку. Курси від компанії
      Apiko відіграли ключову роль в моєму житті, підштовхнули, направили,
      змотивували тому рекомендував би їх усім. Це неоцінений досвід, який
      привів мене до того, ким я є зараз, а саме Junior розробником компанії
      Apiko.
    </p>
  </>
)

const f9 = () => (
  <>
    <p>
      Про курси дізнався у фейсбуці. <br />
      При проходжені курсу найбільше сподобалась те, що він був дійсно об’ємний,
      було багато практики, стек технологій дуже обширний, дізнався багато
      нового.
      <br />
      Стосовно подачі, а це були відео лекції, вони були досить хороші, з
      мінусів можу відмітити те що дивився їх у повторі, і не було як такої
      можливості задати питання “вживу“, але завжди була можливість
      поспілкуватися в чаті, як з самими менторами, так і з учасниками курсу.
      <br />
      Курси можу рекомендувати всім, хто хоче спробувати або покращити свої
      навички у фронтенд розробці
    </p>
  </>
)

const f10 = () => (
  <>
    <p>
      Про курси я дізнався в компанії, відразу вирішив приєднатись. При
      проходженні мені найбільше сподобалась жива подача матеріалу і можливість
      згодом переглянути лекції на каналі в Youtube. Труднощів особливо не
      виникало. Ментори роблять рев'ю пул реквестів в github і вказують на
      помилки, що я вважаю дуже круто! Я рекомендую Apiko курси всім, хто хоче
      освіжити свої теоретичні знання та спробувати себе в написанні наближеного
      до реального життя додатку під наглядом ментора)
    </p>
  </>
)

const f11 = () => (
  <>
    <p>
      Вважаю курси Apiko одними з кращих і можу з упевненістю сказати, що саме
      завдяки цим курсам я розпочав свою кар'єру в ІТ. Курси покривають весь
      основний стек веб технологій та дають хорошу базу для бекенд розробки.
      Інформація подається зрозуміло, починаючи від базових речей JS закінчуючи
      вивчення основних фреймворків. Великим плюсом є практичні завдання, які
      допомагають закріпити отримані знання. Цікаво, якісно, креативно - ви
      вмієте надихати!!! ;)
      <br />
      - Звідки дізнались про курси? <br />
      Про курси дізнався від знайомого, який проходив ці курси раніше, і завдяки
      яким отримав роботу.
      <br />
      - Що найбільше сподобалось при проходженні курсу? <br />
      Найбільше сподобалось формат курсів - подача інформації, домашні завдання
      для закріплення, і сам концепт - спочатку вивчались ази JS, потім
      фреймворки, і саме головне - під час навчання розробляли застосунок.
      <br />
      - Як щодо подачі матеріалу? Які були плюси і які мінуси? <br />
      Подача матеріалів хороша. Були випадки коли ментори були не підготовлені,
      то проект не стартував, то проектора немає чи ще щось. Потрібно до цього
      питання підходити більш організовано.
      <br />
      - Які труднощі виникали під час проходження курсу? Як вирішував їх? Чи
      допомагали ментори? Серйозних труднощів не виникало, ментори завжди
      допомагали коли щось було не зрозуміло.
      <br />
      - Що на твою думку варто покращити? <br />
      Варто покращити концепт - не було загальної картини що розробляли. Було б
      добре при вивченні наприклад core js почати писати якийсь додаток, потім
      постійно його вдосконалювати і переписувати згідно поданого матеріалу.
      <br />
      - Що дали тобі курси? <br />
      Курси дали мені базу знань, яка допомогла мені отримати роботу в ІТ та
      рухатись далі.
      <br />
      - Чи рекомендуєш Apiko курси друзям, знайомим? <br />
      Рекомендую усім курси апіко! Сам і досі інколи можу переглядати деякі
      відео з курсів, якщо потрібна якась мені досі невідома інформація.
    </p>
  </>
)

const f12 = () => (
  <>
    <p>
      Про курси дізнався з Facebook. <br />
      При проходженні курсу найбільше сподобалися цікаві та різноманітні домашні
      завдання.
      <br />
      Матеріал подавався в цілому досить добре. З плюсів це багато підготовленої
      інформації, як у відео форматі, так і текстової. З мінусів можу відмітити,
      що деякі відео, як на мене, були досить довгими.
      <br />
      Суттєвих труднощів не виникало, були на початках моменти з оформлення
      домашніх завдань, але це швидко вирішилося в груповому чаті. Також варто
      відмітити роботу менторів, які постійно допомагали і виправляли, не завжди
      це відбувалося миттєво, але потрібно відмітити, що працюючи хлопці
      постійно знаходили час на допомогу.
      <br />
      На мою думку, можна розбити надто довгі відео на коротші, при можливості,
      сконцентрувати увагу на проблемних місцях.
      <br />
      Курси дали можливість структурувати процес навчання, здобути більше
      практичних навиків, зрозуміти деякий матеріал, познайомитися з цікавими
      людьми.
      <br />
      Даний курс можна сміло рекомендувати друзям та знайомим, що в принципі я і
      зробив :)
    </p>
  </>
)

const f13 = () => (
  <>
    <p>
      Про курси Apiko я дізнався в університеті. На початку вагався чи варто
      іти, але потім зрозумів що варто спробувати. І не жалію! Під час
      проходження курсу по front-end було багато різноманітних практичних
      завдань, які потрібно було вирішити і це є великим плюсом. Деякі із задач
      здавалися на перший погляд досить складними, але потративши хвилин 10 на
      роздуми та переглянувши ще раз поданий матеріал, вони вже не викликали
      труднощів. Також не можу не згадати менторів, оскільки дуже їм вдячний, за
      те, що відповідали на кожне дурне і не зрозуміле запитання із терпінням та
      розумінням. Дані курси дали мені не тільки нові знання, а й великий
      поштовх у front-end напрямку, після їх проходження я зрозумів чого я хочу
      більше і на що краще тратити свій час. А курси від Apiko я вже
      порекомендував своїм друзям, деякі із них з нетерпінням чекають нового
      набору.
    </p>
  </>
)

const f14 = () => (
  <>
    <p>
      Про курси я дізнався від брата. <br />
      Мені сподобалася навчальна атмосфера та цікаві задачі. А також те, що
      подача матеріалу зрозуміла та доступна.
      <br />
      Через основну роботу інколи не вистачало часу, але я старався все
      встигнути. Ментори дуже допомагали з тими проблемами, які виникали.
    </p>
  </>
)

const f15 = () => (
  <>
    <p>
      Приятель розповів про курси. <br />
      На курсі сподобалась якісна подача матеріалу, мінімум води. <br />
      Рідко виникали складнощі при виконанні завдань, в основному отриманих
      знань було достатньо, щоб вирішити їх. Але ж коли виникала потреба в
      допомозі, то ментори помагали.
      <br />
      Завдяки курсу я побачив прогалини у знаннях та заповнив їх. Закріпив
      знання React.js та redux. Готовий до роботи з справжніми проектами.
    </p>
  </>
)

const f16 = () => (
  <>
    <p>
      Найбільше на курсі сподобалось фінальне завдання, де можна було повноцінно
      створити свій робочий проект з бекендом і готовим дизайном. Також
      сподобалось, що матеріал подавався у вигляді відео курсів, а вже задати
      запитання можна було на зустрічах. Труднощі виникали на етапі чистого
      javascript. Раніше вивчав все хаотично, не було послідовного плану, тому
      почав з реакту своє навчання (що звісно було помилкою). Було б добре крім
      теорії надавати більше прикладів, де це можна використати. Курси дали мені
      більш впевнені знання в javascript, react, redux.
    </p>
  </>
)

const f17 = () => (
  <>
    <p>
      Про курси Apiko.Academy дізнався з мережі LinkedIn, прочитавши програму
      курсу, одразу зрозумів, це те, що мені потрібно.
      <br />
      Курси покривають весь базовий стек технологій для того, щоб почати кар'єру
      Frontend розробника. Дуже добре поданий матеріал у відео лекціях, цікаві
      та не нудні практичні завдання, круті ментори завжди допоможуть якщо щось
      не зрозуміло.
      <br />
      Подача матеріалу на високому рівні, відео лекції, до яких ти можеш
      повернутись в будь-який момент, спілкування з досвіченими менторами курсу,
      я яких ти одразу починаєш набиратись досвіду.
      <br />
      При проходженні курсу як таких труднощів не виникало, були певні труднощі
      під час написання фінального завдання, але з за допомогою менторів все
      вирішувалось. На мою думку, на початку курсу, варто більше приділити увагу
      технології GIT, а також більше виділити часу на вивчення Redux.
      <br />
      З курсів я отримав хорошу базу знань, які одразу мені допомогли мені
      знайти роботу Frontend розробника.
      <br />
      Можу сміливо рекомендувати курси Apiko.Academy всім хто хоче розпочати
      свою кар'єру в ІТ. Отримаєте хороші знання, нові цікаві знайомства, та
      можливість розпочати роботу у сфері ІТ технологій.
    </p>
  </>
)
const f18 = () => (
  <>
    <p>
      Про курси дізнався спочатку з лінкедіну, і згодом побачив ще рекламу в
      фейсбуці
      <br />
      При проходженні курсу найбільше сподобалось те, що є підтримка менторів,
      адже це дуже важливий аспект при вивченні і покращенні своїх знань.
      Завдяки менторам я зрозумів, як робити не потрібно, і які альтернативні
      методи рішень є при цьому. Ще сподобалось що лекції онлайн, і ти можеш їх
      переглянути в будь-який вільний для тебе час, що додає гнучкості графіку
      дня.
      <br />
      Матеріал подається дуже круто, все детально розказано та розжовано. Дуже
      зручно, що ще є додаткові матеріали лекції, які можна почитати щоб більш
      детальніше розібратись з поданою інформацією.
      <br />
      З мінусів можу назвати хіба те, що трішки важче зайшов розділ з Redux
      (інформація була подана чітко, розжовано і зрозуміло), але мені легше
      заходить інформація, коли матеріал подають в стилі переказу, а тут подання
      було монотонним голосом і через це трішки важче сприймався матеріал.
      <br />
      Не обійшлось звісно ж і без труднощів в процесі проходження курсу, всі
      проблеми та помилки і не згадаєш. Десь були проблеми з кодом, десь з ПЗ,
      разок з гітом і конфліктами. Вирішення проблем спочатку шукав в гуглі,
      якщо уже доволі довший час не міг знайти відповіді, то звісно звертався до
      менторів, які залюбки допомагали вирішити дані питання. Менторам окреме
      величезне дякую за допомогу, адже деякі ментори не тільки в чаті
      допомагали, але й робили кол і вживу детальніше все пояснювали!!)🔥
      <br />
      З покращень важко навіть сказати що ще б хотілось так як все дуже
      сподобалось, хіба було б класно якщо б спікінг клаб був від початку курсу,
      адже англійська це теж дуже важливий аспект при прийнятті на роботу.
      <br />
      Курси дали мені нереально велику базу нових знань, за які я дуже вдячний!
      Після місяця курсів я зрозумів наскільки малою кількістю інформації я
      володів по джаваскріпту. Завдяки курсам я отримав також більшу впевненість
      в собі та в своїх знаннях, що дозволило мені проходити успішно технічні
      інтерв‘ю та рухатись далі уже в сторону кар‘єрного шляху ІТ.
      <br />
      Курси уже рекомендував друзям і однозначно буду ще рекомендувати в
      подальшому всім знайомим, адже в мене залишились тільки позитивні емоції
      та спогади про цей курс, і також я знаю що він дуже дієвий та корисний в
      плані опанування нових знань!
    </p>
  </>
)

const f19 = () => (
  <>
    <p>
      Про курси Apiko я дізнався на лекції в університеті. Мені дуже сподобались
      курси, тому що я вивчив дуже багато нового і вдосконалив ті навички, які
      мав до цього. Подача матеріалу сподобалась. Найбільший плюс це робота з
      менторами та code review, мінуси дистанційне спілкування.
      <br />
      Проблеми були з оптимізацією коду, проте, ментори допомагали це вирішити.
      Я однозначно рекомендую курси Apiko, тому що це якісні, цікаві і корисні
      курси для початку роботи в IT.
    </p>
  </>
)

const f20 = () => (
  <>
    <p>
      Про курси дізнався від товариша який на той момент вже працював у
      компанії, а також бачив анонс у телеграм-каналі.
      <br />
      Найбільше сподобалось виконувати щотижневі завдання, а також вирішувати
      помилки з якими зустрічався протягом курсу. Щодо подачі матеріалу особисто
      мені все сподобалось. Короткі зрозумілі відео, а навіть якщо і не
      зрозуміло щось, то завжди можна було звернутися до додаткових ресурсів,
      які були доступні під кожним відео.
      <br />
      Труднощі виникали дуже навіть часто, спочатку пробував самостійно знайти
      відповідь чи рішення, але коли заходив в тупік, тоді звертався до
      менторів, які на кожне моє питання відповіли.
      <br />У першу чергу курси дали мені знання, якими я навіть близько не
      володів до їх проходження лише базові навички, а також професію до якої
      прагнув.
    </p>
  </>
)

export const feedbackList = [
  {
    name: "Іван Кивацький",
    purpose: "Випускник 2nd Apiko Intensive",
    msg: f1,
    componentNextToName: lkdn("https://www.linkedin.com/in/ivan-kyvatskyi/"),
    photo: a1,
  },

  {
    name: "Роман Кушицький",
    purpose: "Випускник 2nd Apiko Intensive",
    msg: f11,
    componentNextToName: lkdn(
      "https://www.linkedin.com/in/roman-kushytskyy-54057311b/"
    ),

    photo: a11,
  },

  {
    name: "Юлія Бобко",
    purpose: "Випускниця 7th Apiko Intensive",
    msg: f2,
    componentNextToName: lkdn(
      "https://www.linkedin.com/in/yulia-bobko-b9422b10a/"
    ),
    photo: a2,
  },
  {
    name: "Андрій Петрик",
    purpose: "Випускник 7th Apiko Intensive",
    msg: f3,
    photo: a3,
  },

  {
    name: "Ростислав Підбурачинський",
    purpose: "Випускник 8th Apiko Intensive",
    msg: f4,
    photo: a4,
    componentNextToName: lkdn(
      "https://www.linkedin.com/in/rostyslav-pidburachynskyi-462a59184/"
    ),
    noExtend: true,
  },

  {
    name: "Петро Лящинський",
    purpose: "Випускник 8th Apiko Intensive",
    msg: f5,
    photo: a5,
    componentNextToName: lkdn("https://www.linkedin.com/in/rainbowmrx/"),
  },

  {
    name: "Василь Костін",
    purpose: "Випускник 7th Apiko Intensive",
    msg: f6,
    photo: a6,
    componentNextToName: lkdn(
      "https://www.linkedin.com/in/vasyl-kostin-478803189/"
    ),
  },

  {
    name: "Анонімний відгук",
    purpose: "",
    msg: f7,
    photo: a7,
  },

  {
    name: "Сергій Циб",
    purpose: "Випускник 8th Apiko Intensive",
    msg: f8,
    photo: a7,
  },

  {
    name: "Сергій Трачук",
    purpose: "Випускник 8th Apiko Intensive",
    msg: f9,
    photo: a9,
  },

  {
    name: "Вадим Миронов",
    purpose: "Випускник 7th Apiko Intensive",
    msg: f10,
    photo: a10,
    componentNextToName: lkdn("https://www.linkedin.com/in/vadym-myronov/"),
  },

  {
    name: "Микола Януш",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f12,
    photo: a12,
    componentNextToName: lkdn(
      "https://www.linkedin.com/in/mykola-yanush-59347899/"
    ),
  },

  {
    name: "Дмитро Граб",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f13,
    photo: a13,
  },

  {
    name: "Роман Тимець",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f14,
    photo: a14,
  },

  {
    name: "Михайло Пастернак",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f15,
    photo: a15,
  },

  {
    name: "Сергій Сав'юк",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f16,
    photo: a16,
  },

  {
    name: "Олег Лаврик",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f17,
    photo: a17,
  },

  {
    name: "Андрій Кукляк",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f18,
    photo: a18,
  },

  {
    name: "Вадим Боярчук",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f19,
    photo: a19,
  },

  {
    name: "Віталій Кульчицький",
    purpose: "Випускник Apiko Academy Front-end Spring 2021 course",
    msg: f20,
    photo: a20,
  },
]

const fList2 = feedbackList.map((i) => ({
  ...i,
  id: `${i.name}${i.photo}`,
  expanded: false,
}))
