import React from "react"
import styled from "@emotion/styled"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export const Ewrapper = styled.div`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  position: relative;
  padding: 8.125em 8em 0 8em;
  transition: all 0.2s ease-in-out;
  @media (max-width: 640px) {
    padding: 5em 0.18em 0px 0.18em;
  }
`

export const Efigure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 3em 0 0 0;
  width: 100%;
`

const EFigcaption = styled.figcaption`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EName = styled.p`
  color: #333;
  margin: 0.3em 0 0 0;
  font-size: 2em;
  text-align: center;
  font-weight: 600;
`

const EPosition = styled.div`
  display: flex;
  font-size: 1.3em;
  color: #8f96a1;
  margin: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
`

const EImg = styled.img`
  border-radius: 50%;
  width: 6.876em !important;
  height: 6.876em !important;
  object-fit: cover;
`

export const Emsg = styled.div`
  max-height: ${(props) => (props.expanded ? "unset" : "14.01em")};
  overflow: hidden;

  @media (max-width: 640px) {
    max-height: ${(props) => (props.expanded ? "unset" : "14.9em")};
  }
  
  p {
    line-height: 1.7;
    position: relative;
    text-align: initial;
    margin: 0;
    font-size: 1.5em;
    color: #1d293f;

    @media(max-width: 640px) {
    line-height: 1.4;

    }


    @media (min-width: 641px) {
      font-size: 1.6em;
    }
  }
`

const EPersonImgWrapper = styled.div`
  position: relative;
`

const ELearnMore = styled.button`
  position: relative;
  color: #fd7114;
  font-weight: 400;
  background: none;
  border: 0;
  outline: none;
  font-size: 1.6em;
  margin: 0;
  padding: 0;
  margin-top: 0.2em;
`

const Component = ({
  name,
  purpose,
  msg,
  photo,
  logo,
  imgs,
  MessageFontSizeEms,
  componentNextToName,
  noExtend,
  expanded,
  toggleExpandedState,
  id,
}) => {
 

  return (
    <Ewrapper>
      <Emsg fontSize={MessageFontSizeEms} expanded={expanded}>
        {msg()}
      </Emsg>

      {!noExtend && (
        <ELearnMore
          onClick={() => {
            toggleExpandedState(id)
          }}
        >
          {expanded ? <>Менше</> : <>Читати далі</>}
        </ELearnMore>
      )}
      <Efigure>
        <EPersonImgWrapper>
          <EImg src={photo} alt={name} />
        </EPersonImgWrapper>

        <EFigcaption>
          <EName>
            {name}
            {!!componentNextToName && <>{componentNextToName}</>}
          </EName>
          <EPosition>{purpose}</EPosition>
        </EFigcaption>
      </Efigure>
    </Ewrapper>
  )
}

export const CarouselElement = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "studentFeedbacks" } }) {
          edges {
            node {
              id
              name
              absolutePath
              relativePath
              publicURL
              childImageSharp {
                fixed(width: 90, pngCompressionSpeed: 1, quality: 80) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(imgs) => <Component {...props} imgs={imgs} />}
  />
)
